// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBWlc0QbLp3ekzywZMGMT9k1_TSNu1j55I",
    authDomain: "ambihome-db.firebaseapp.com",
    databaseURL: "https://ambihome-db.firebaseio.com",
    projectId: "ambihome-db",
    storageBucket: "ambihome-db.appspot.com",
    messagingSenderId: "939163499373",
    appId: "1:939163499373:web:dda01922f8886ca0423864"
  },
  // firebaseConfig: {
  //   apiKey: 'AIzaSyBi_3BMIaCwK1uAQSyLNkfTHzE5juLYrVQ',
  //   authDomain: 'ambidummydb.firebaseapp.com',
  //   databaseURL: 'https://ambidummydb.firebaseio.com',
  //   projectId: 'ambidummydb',
  //   storageBucket: 'ambidummydb.appspot.com',
  //   messagingSenderId: '506734703373',
  //   appId: '1:506734703373:web:107d52f4972d533e657644'
  // },
  matrixToolVersion: require('./../../package.json').version,
  lambdaAutoIntegrate: 'https://an64nit1ie.execute-api.eu-central-1.amazonaws.com/master/auto_integrator',
  lambdaDocuments: 'https://t9mp62m4gb.execute-api.eu-central-1.amazonaws.com/master/documents',
  lambdaSevdesk: 'https://tbvnkvy7kg.execute-api.eu-central-1.amazonaws.com/master/createOrder',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

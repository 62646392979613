import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { AppComponent } from './app.component';
import {Router, RouterModule, Routes} from "@angular/router";
import {environment} from "../environments/environment";
import {AngularFireStorage, AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireModule} from "@angular/fire";
import { HttpClientModule } from '@angular/common/http';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {LoginComponent} from "./login.component";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FlexLayoutModule} from "@angular/flex-layout";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {version as knowledgeVersion} from "ambihome-knowledge/ambihome/ts/index";

registerLocaleData(localeDe, 'de');

const unauthorizedRedirect = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', loadChildren: () => import('./logged-in/logged_in.module').then(m => m.LoggedInModule), canActivate: [AngularFireAuthGuard], data: { authGuardPipe: unauthorizedRedirect }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    FlexLayoutModule,
    HttpClientModule,
    MatSnackBarModule,

    MatButtonModule,
    MatInputModule,
    FormsModule
  ],
  providers: [AngularFireStorage, AngularFireAuthGuard],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor() {
    console.log('versionInfo', {
      cadToolVersion: environment.matrixToolVersion,
      knowledgeVersion: knowledgeVersion
    });
  }
}


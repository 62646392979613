import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/auth";
import {Router} from "@angular/router";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  template: `
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <form (ngSubmit)="submit()">
          <div>
              <mat-form-field>
                  <input placeholder="Email" matInput [(ngModel)]="email" name="email" required>
              </mat-form-field>
          </div>
          <div>
              <mat-form-field>
                  <input placeholder="Password" type="password" matInput [(ngModel)]="password" name="password" required>
              </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="end">
              <button mat-raised-button type="submit">login</button>
          </div>
        </form>
    </div>
  `,
  styles: [`
    
  `]
})
export class LoginComponent {
  email: string;
  password: string;

  submit() {
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)
      .then(() => this.router.navigate(['/']))
      .catch((err) => {
        this.snacky.open(err.message, 'OK');
        console.log('err', err)
      });
  }

  constructor(private afAuth: AngularFireAuth, private router: Router, private snacky: MatSnackBar) {
  }
}

